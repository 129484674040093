import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import OrderItem from '@/components/items/OrderItem.vue';
import { getOrders } from '@/api/dashboard.js';
let LatestResults = class LatestResults extends Vue {
    constructor() {
        super(...arguments);
        this.orderedServices = [];
    }
    async mounted() {
        const response = await getOrders();
        this.orderedServices = this.filterOrderedServices(response);
    }
    filterOrderedServices(array) {
        const sortFn = (c, d) => Date.parse(d.date) - Date.parse(c.date);
        return array.sort(sortFn).slice(0, 12);
    }
};
LatestResults = __decorate([
    Component({
        components: {
            OrderItem,
        },
    })
], LatestResults);
export default LatestResults;
