<template lang="pug">
.doc-item(@click="goToDocumentPage(doc.id)")
  img.doc-item__image(:src="getImageLink(doc.files) || require('@/assets/PDF2.svg')")
  .doc-item__title {{ doc.name }}
  .doc-item__date {{ $date(new Date(doc.created_at), 'd MMM yyyy') }}
  .doc-item__icon
    BaseStatus(onlyIcon :status="doc.status" type="document")
    //- icon(:name="statusIcons[doc.status]")
    //- icon(v-if="doc.files.length && doc.files[0].status" :name="statusIcons[doc.files[0].status]")
</template>

<script>
import { getImageLink } from '@/utils/utils'; 
import BaseStatus from '@/components/UI/BaseStatus.vue';


// TODO вынести
const statusIcons = {
  1: 'doc-error-icon',
  2: 'doc-error-icon',
  3: 'doc-progress-icon',
  4: 'doc-success-icon',
  5: 'doc-success-icon',
}

export default {
  components: {BaseStatus},
  data() {
    return {
      statusIcons
    }
  },
  computed: {
    documentTypes() {
      return this.$store.state.staticVariables.staticVariables?.document_types || [];
    },
  },
  props: ['doc'],
  methods: {
    getImageLink,
    goToDocumentPage(id) {
      this.$router.push({ 
        name: 'singleDocumentPage',
        params: { id }
      });
    },
    getDocType(docType) {
      return this.documentTypes.find((el) => el.value === docType)?.description;
    }
  }
}
</script>

<style lang="scss">
  .doc-item {
    width: 90px;
    height: 150px;
    padding: 10px;
    border-radius: 8px;
    font-size: 12px;
    position: relative;
    cursor: pointer;
    transition: background .2s;
    &:hover {
      background: $light-background;
    }
  }

  .doc-item__image {
    width: 60px;
    height: 90px;
    object-fit: cover;
    border: 1px solid #E9E8FF;
    border-radius: 8px;
  }

  .doc-item__title {
    font-Weight: 500;
    color: $grey-2;
    @include trim-text(1)
  }

  .doc-item__date {
    color: $grey-4;
    white-space: nowrap;
  }

  .doc-item__icon {
    top: 4px;
    left: 4px;
    position: absolute;
  }
</style>