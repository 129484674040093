<template lang="pug">
router-link.order-item(:to="routerLinkTo" :class="{_dashboard: dashboard}")
  .order-item__info
    .order-item__date {{$date(new Date(order.date), 'dd.MM.yyyy')}}
    .order-item__title(:title="order.name") {{order.name}}
    .order-item__quanitity-price: QuantityPrice(:quantity="order.results_count" :price="order.price")
  .order-item__footer
    .order-item__status(v-if="order.status"): BaseStatus(:status="order.status" type="order")
    .order-item__badge(v-if="showBadge"): BaseBadge(value="Новый" background="#7C74E9" :outline="true" color="#424056")
    .order-item__pdf: DownloadFile(:sid="order.id" type="sm")
  .order-item__button: NextBtn
</template>
 
<script>
import {userFirstVisit} from '@/utils/utils.ts'
import BaseBadge from '@/components/UI/BaseBadge.vue';
import BaseStatus from '@/components/UI/BaseStatus.vue';
import QuantityPrice from '@/components/UI/QuantityPrice.vue';
import NextBtn from '@/components/UI/buttons/NextBtn.vue';
import { IRouter } from '@/interfaces/router.interface';
import DownloadFile from '@/components/DownloadFile.vue';

const isOldOrder = (order) => {
  const startDate = new Date ("2022-01-31")
  const currentDate = new Date (order.date)

  return startDate > currentDate
}

export default {
  data() { 
    return {
      showBadge: !isOldOrder(this.order) && userFirstVisit({localStorageItem: 'showedNewOrders', id: this.order.id })
    }
  },
  methods: {
    goToOrderPage() {
      this.$router.push({ 
        name: IRouter.ROUTE_NAME.ORDER_PAGE,
        params: {id: this.order.id}
      });
    }
  },
  computed: {
    routerLinkTo() {
      return {
        name: IRouter.ROUTE_NAME.ORDER_PAGE,
        params: {id: this.order.id}
      } 
    }
  },
  components: {
    BaseBadge,
    BaseStatus,
    NextBtn,
    QuantityPrice,
    DownloadFile
  },
  props: ['order', 'dashboard']
}
</script>

<style lang="scss">
.order-item {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 20px 23px;
  display: grid;
  grid-template-columns: 1fr 60px;
  grid-template-rows: 1fr 25px;
  gap: 5px;
  align-items: center;
  height: 186px;
  text-decoration: none;
  &:hover {
    .next-btn {
      background: #7C74E9;
      border-color: transparent;
      color: white
    }
  }
  .next-btn {
    transition: .2s
  }
}

.order-item__info {
  grid-area: 1/1;
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.order-item__title {
  font-size: 14px;
  line-height: 18px;
  color: $grey-2;
  font-weight: 500;
  @include trim-text(3);
}

.order-item__date {
  font-size: 12px;
  line-height: 15px;
  color: $accent-color;
  display: none;
  .order-item._dashboard & {
    display: block
  }
}

.order-item__button {
  grid-area: 1/2;
  justify-self: end;
  align-self: start;
}

.order-item__footer {
  grid-column: 1/3;
  grid-row: 2;
  display: flex;
  gap: 24px;
  align-items: center;
}

.order-item__pdf {
  margin-left: auto;
}

// .order-item__status {
//   grid-area: 2/1;
//   align-self: center;
// }

// .order-item__badge {
//   grid-area: 2/2;
//   align-self: center;
// }

</style>