// TODO привести все кнопки в приложенпи в порядок
<template>
  <div class="empty-container _benchmarks">
    <div class="empty-container__title _benchmarks">Тут будут ваши анализы  😱</div>
    <div class="empty-container__body _benchmarks">
      <div class="empty-container__actions _benchmarks">
        <BaseBtn @click="isFileModalOpen = true">
          <icon name="add-icon" width="10px" />
          <span>Расшифровать анализ</span>
          <ButtonDivider/>
          <LockLabel white :locked="$store.state.dashboard.results.length >= 5">{{$store.state.dashboard.results.length}}</LockLabel>
        </BaseBtn>
        <MainBtn
          class="_out"
          type="small-bg"
          text="Загрузить вручную"
          @click.native="isShowManualAdditionModal = true"
        />
        <FreeTariffButton style="margin-left: auto" modalType="dashboard"/>
      </div>
    </div>

    <ManualAddition v-model="isShowManualAdditionModal"/>
    <AddFileModal 
      decipherAnalyzes
      fileModalType='analyze'
      :is-file-modal-open="isFileModalOpen" 
      @close-modal="isFileModalOpen = false" 
    />
  </div>
</template>

<script>
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import ManualAddition from '@/components/modals_new/complex/ManualAddition.vue';
import AddFileModal from '@/components/modals/addFileModal/AddFileModal.vue';
import LockLabel from '@/components/LockLabel.vue';
import ButtonDivider from '@/components/UI/ButtonDivider.vue';
import FreeTariffButton from '@/components/FreeTariffButton.vue';


export default {
  data() {
    return {
      isShowManualAdditionModal: false,
      isFileModalOpen: false
    }
  },
  components: {
    MainBtn,
    ManualAddition,
    AddFileModal,
    LockLabel,
    ButtonDivider,
    FreeTariffButton
}
}
</script>

<style lang="scss">
  .empty-container {
    display: flex;
    flex-direction: column;
    gap: 14px;
    
    &._benchmarks {
      width: calc(75% - 70px);
      @include media-down($breakpoint-lg) {
        width: calc(50% - 20px);
      }

      @include media-down($breakpoint-pre-md) {
        margin-bottom: 20px;
        width: 100%;
      }
    }

    &._docs {
      width: 25%;
      justify-content: stretch;
      @include media-down($breakpoint-lg) {
        width: 50%;
      }

      @include media-down($breakpoint-pre-md) {
        width: 100%;
        order: 2;
      }
    }
  }

  .empty-container__body {
    height: 170px;
    padding: 25px;
    display: flex;
    align-items: flex-end;
    border-radius: 24px;
    @include border-dashed(2, 5, 'D4D1FD', 24);

    &._docs {
      height: 278px;
    }
    @include media-down($breakpoint-md) {
      height: auto !important;
    }
  }

  .empty-container__title {
    font-size: 14px;
    line-height: 130%;
    color: #A0A0B8;
    @include media-down($breakpoint-md) {
      margin-top: 30px;
      text-align: center;
    }
  }

  .empty-container__actions {
    &._benchmarks{
      display: flex;
      gap: 27px;
      flex-wrap: wrap;
      width: 100%;
    }

    &._docs {
      width: 100%;
    }

    @include media-down($breakpoint-md) {
      flex-direction: column;
      width: 100%;
      gap: 14px !important;
    }
  }
</style>