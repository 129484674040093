<template>
  <div class="benchmarks">
    <div>
      <h3 class="benchmarks__title">
        Последние загруженные результаты анализов
      </h3>
      <div class="benchmarks__list">
        <AnalyzeTH :values="analyzeTHData"/>
        <AnalyzeItem
          type="link"
          v-for="benchmark in dashboardResults"
          :analyze="benchmark"
          :key="benchmark.id"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AnalyzeItem from '@/components/items/AnalyzeItem.vue';
import AnalyzeTH from '@/components/analyzes/AnalyzeTH.vue';

export default {
  data(){
    return {
    analyzeTHData: ['Биомаркер',
      'Значение',
      'Ед. изм.',
      'Референс. значения',
      'Лаборатория',
      'Дата']
    }
  },
  components: {
    AnalyzeItem,
    AnalyzeTH
  },
  props: {
    dashboardResults: Array
  }
}
</script>

<style lang="scss" scoped>
.benchmarks__title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 14px;
  line-height: 20px;
  @include media-down($breakpoint-md){
     margin-bottom: 24px;
  }
}

.benchmarks__list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  @include media-down($breakpoint-md){
    gap: 8px
  }
}
</style>
