export const documentAllStatuses = {
  success: {
    value: 'success',
    icon: 'doc-success-icon',
    description: 'Успешно распознан',
    infoText: 'Успешно распознан',
    tooltipText: 'Все успешно расшифровано! Пожалуйста, перепроверьте корректность данных.',
    rule: (files) => files.every((file) => file.status === 7),
  },
  error: {
    value: 'error',
    icon: 'doc-error-icon',
    description: 'Ошибка',
    infoText:
      'Нам не удалось расшифровать документ 😔 <br>Нажмите на документ, чтобы узнать причину.',
    tooltipText: 'К сожалению, произошла ошибка. Попробуйте загрузить файл заново.',
    rule: (files) => files.every((file) => [2, 9].includes(file.status)),
  },
  process: {
    value: 'process',
    icon: 'doc-process-icon',
    description: 'Распознается',
    infoText:
      'Файлы находятся в процессе распознавания и скоро появятся здесь. <br>Обычно это занимает до 1 часа.',
    tooltipText: 'Загруженные файлы в процессе расшифровки.',
    rule: (files) => files.some((file) => [0, 1, 3].includes(file.status)),
  },
  progress: {
    value: 'progress',
    icon: 'doc-progress-icon',
    description: 'Частично распознан',
    infoText: 'Частично расшифрован <br>Нажмите на документ, чтобы узнать причину.',
    tooltipText:
      'Некоторые результаты мы не смогли расшифровать. Пожалуйста, внесите данные вручную.',
    rule: null,
  },
};

export const documentAllStatusesNew = {
  1: {
    value: 'success',
    icon: 'doc-success-icon',
    description: 'Успешно распознан',
    infoText: 'Успешно распознан',
    tooltipText: 'Все успешно расшифровано! Пожалуйста, перепроверьте корректность данных.',
    rule: (files) => files.every((file) => file.status === 7),
  },
  2: {
    value: 'error',
    icon: 'doc-error-icon',
    description: 'Ошибка',
    infoText:
      'Нам не удалось расшифровать документ 😔 <br>Нажмите на документ, чтобы узнать причину.',
    tooltipText: 'К сожалению, произошла ошибка. Попробуйте загрузить файл заново.',
    rule: (files) => files.every((file) => [2, 9].includes(file.status)),
  },
  0: {
    value: 'process',
    icon: 'doc-process-icon',
    description: 'Распознается',
    infoText:
      'Файлы находятся в процессе распознавания и скоро появятся здесь. <br>Обычно это занимает до 1 часа.',
    tooltipText: 'Загруженные файлы в процессе расшифровки.',
    rule: (files) => files.some((file) => [0, 1, 3].includes(file.status)),
  },
  3: {
    value: 'progress',
    icon: 'doc-progress-icon',
    description: 'Частично распознан',
    infoText: 'Частично расшифрован <br>Нажмите на документ, чтобы узнать причину.',
    tooltipText:
      'Некоторые результаты мы не смогли расшифровать. Пожалуйста, внесите данные вручную.',
    rule: null,
  },
};

export const getDocumentStatus = (files = []) => {
  const { process, error, progress, success } = documentAllStatuses;

  if (process.rule(files)) return process;
  if (success.rule(files)) return success;
  if (error.rule(files)) return error;
  return progress;
};
