<template>
  <div class="empty-container _docs">
    <div class="empty-container__title _docs">А тут будут ваши документы  😏</div>
    <div class="empty-container__body _docs">
      <div class="empty-container__actions _docs">
        <BaseBtn size="sm" @click="isFileModalOpen = true" style="width: 100%">
          <icon name="add-icon" width="10px" />
          <span>Добавить документ</span>
          <ButtonDivider/>
           <LockLabel :locked="$store.state.dashboard.results.length >= 5" white>{{$store.state.dashboard.results.length}}/5</LockLabel>
        </BaseBtn>
      </div>
    </div>
        <AddFileModal 
          decipherAnalyzes
          fileModalType='document'
          :is-file-modal-open="isFileModalOpen" 
          @close-modal="isFileModalOpen = false" 
        />
  </div>
</template>

<script>
import AddFileModal from '@/components/modals/addFileModal/AddFileModal.vue';
import LockLabel from '@/components/LockLabel.vue';
import ButtonDivider from '@/components/UI/ButtonDivider.vue';
export default {
  data(){
    return {
      isFileModalOpen: false
    }
  },
  components: {ButtonDivider, LockLabel, AddFileModal}
}
</script>