import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { recomendationTypes } from '@/utils/utils';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
let SummaryCard = class SummaryCard extends Vue {
    constructor() {
        super(...arguments);
        // statItems: IStatItem[] = [];
        this.fullStatistic = false;
        this.isOpenDashboardPopup = false;
    }
    get recomendation() {
        return recomendationTypes;
    }
    get recomendationType() {
        const { out_of_norm, border_zone } = this.overview;
        const analyzeQuantity = this.analyzeQuantity;
        if (analyzeQuantity === 0) {
            return 'noResults';
        }
        const result = ((out_of_norm + border_zone) / analyzeQuantity) * 100;
        return result === 0 ? 'good' : result <= 50 ? 'norm' : 'bad';
    }
    get overview() {
        return this.$store.state.dashboard.overview;
    }
    get statItems() {
        return [
            {
                value: this.overview.in_norm,
                text: 'В норме',
                color: '#63C58A',
                status: 'ok',
            },
            {
                value: this.overview.border_zone,
                text: 'Пограничные зоны',
                color: '#FFDE79',
                status: 'ok',
            },
            {
                value: this.overview.out_of_norm,
                text: 'Не в норме',
                color: '#FF7C7C',
                status: 'danger',
            },
        ];
    }
    get analyzeQuantity() {
        return this.overview.in_norm + this.overview.border_zone + this.overview.out_of_norm;
    }
    showFullStatistic() {
        this.fullStatistic = !this.fullStatistic;
    }
    goToAnalyzePage(id) {
        this.$router.push({ name: ROUTE_NAME.ANALYZES_PAGE, params: { id } });
    }
    goToAnalyzesPage(id) {
        this.$router.push({ name: ROUTE_NAME.ALL_ANALYZES, query: { sort: 'out_of_norm_only' } });
    }
};
SummaryCard = __decorate([
    Component({
        components: { MessageModal }
    })
], SummaryCard);
export default SummaryCard;
