import { __decorate } from "tslib";
import { Component } from 'vue-property-decorator';
import { IDashBoard } from '@/interfaces/dashboard.interface';
import { bus } from '@/plugins/bus';
import { IAnalyzes } from '@/interfaces/analyzes.interface';
import PersonalAreaMixin from '@/mixins/personal-area-mixins';
import BaseTabs from '@/components/BaseTabs.vue';
import AddAnalyzesModal from '@/components/modals/AddAnalyzes.vue';
import AddFileModal from '@/components/modals/addFileModal/AddFileModal.vue';
import AddComment from '@/components/modals/AddComment.vue';
import LoadingSpinner from '@/components/UI/LoadingSpinner.vue';
import EmptyBenchmarks from '@/views/DashboardPage/EmptyBenchmarks.vue';
import EmptyDocs from '@/views/DashboardPage/EmptyDocs.vue';
import EmptySummary from '@/views/DashboardPage/EmptySummary.vue';
import SummaryCard from '@/views/DashboardPage/SummaryCard.vue';
import AddResearch from '@/views/DashboardPage/AddResearch.vue';
import AddedDocuments from '@/views/DashboardPage/AddedDocuments.vue';
import LatestResults from '@/views/DashboardPage/LatestResults.vue';
import Benchmarks from '@/views/DashboardPage/Benchmarks.vue';
import FreeTariffButton from '@/components/FreeTariffButton.vue';
import { getResults, getDocuments } from '@/api/dashboard.js';
let IndexPage = class IndexPage extends PersonalAreaMixin {
    constructor() {
        super(...arguments);
        this.tabsData = [
            {
                label: 'Последние заказы',
                name: IDashBoard.TabsName.LATEST_RESULTS,
            },
            {
                label: 'Показатели',
                name: IDashBoard.TabsName.BENCHMARKS,
            },
        ];
        this.addAnalyzesModalState = false;
        this.addCommentModalState = false;
        this.activeTab = IDashBoard.TabsName.LATEST_RESULTS;
        this.isFileModalOpen = false;
        this.isAnalyzeFileModalOpen = false;
        this.loading = false;
        // TODO
        this.dashboardDocuments = [];
        this.dashboardResults = [];
    }
    async created() {
        this.loading = true;
        const [dashboardDocuments, dashboardResults] = await Promise.all([
            getDocuments(),
            getResults(),
        ]);
        this.dashboardDocuments = dashboardDocuments;
        this.dashboardResults = dashboardResults;
        this.loading = false;
    }
    mounted() {
        bus.$on(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_MODAL, () => (this.addAnalyzesModalState = !this.addAnalyzesModalState));
        bus.$on(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT, (id) => {
            this.addCommentModalState = !this.addCommentModalState;
            this.$store.commit('analyzes/setPropertyInStore', { name: 'commentAnalyzesId', value: id });
        });
    }
    get isPatientFromJlab() {
        return this.$store.state.personalArea.patient.from_jlab;
    }
    changeAddModalState() {
        this.addAnalyzesModalState = !this.addAnalyzesModalState;
    }
    addAnalyzesModalClose(value) {
        this.addAnalyzesModalState = value;
    }
    onTabChange(value) {
        this.activeTab = value;
    }
    addCommentClose(value) {
        this.addCommentModalState = value;
    }
    destroy() {
        bus.$off(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_MODAL);
        bus.$off(IAnalyzes.BusEvents.OPEN_ADD_ANALYZES_COMMENT);
        bus.$off(IAnalyzes.BusEvents.EDIT_ANALYZES);
    }
    toggleFileModal(state) {
        this.$store.commit('documents/setPropertyInStore', { name: 'addDocumentToOrder', value: false });
        this.isFileModalOpen = state;
    }
    toggleAnalyzeFileModal(state) {
        this.$store.commit('documents/setPropertyInStore', { name: 'addDocumentToOrder', value: false });
        this.isAnalyzeFileModalOpen = state;
    }
};
IndexPage = __decorate([
    Component({
        components: {
            SummaryCard,
            AddResearch,
            AddedDocuments,
            AddAnalyzesModal,
            AddComment,
            AddFileModal,
            Benchmarks,
            EmptyBenchmarks,
            EmptyDocs,
            EmptySummary,
            LoadingSpinner,
            FreeTariffButton,
            BaseTabs,
            LatestResults
        },
    })
], IndexPage);
export default IndexPage;
