<template lang="pug">
.download-file(:class="{[`_${type}`]: type}" @click.stop="$metrika.reachGoal('userDownloadPdf')")
  a(:href="pdfUrl" target="_blank").download-file__button._pdf
    img(src="@/assets/pdf3.svg")
    span PDF-файл
  .download-file__divider
  a(:href="pdfUrl" download="pdf").download-file__button._download
    icon(name="download-icon" width="20" height="20")
    span PDF-файл
</template>

<script>
import {downloadPdf} from '@/api/web.js'
export default {
  props: ['sid', 'type'],
  computed: {
    pdfUrl(){
      const { patient_id, name, surname, patronymic } = this.$store.state.personalArea.patient;
      return `https://web.labstori.ru/rezults?fio=${surname} ${name} ${patronymic}&id=${patient_id}&sid=${this.sid}&pdf=true&pat=true&header=true`
    }
  },
}
</script>

<style lang="scss">
  .download-file {
    border-radius: 15px;
    padding: 8px;
    background: #FFFFFF;
    display: flex;
    align-items: center;
    width: fit-content;
    gap: 8px;
    &._sm {
      border: 1px solid $grey-6;
      padding: 0;
      &:hover {
        border-color: $grey-5;
      }
    }
  }

  .download-file__button {
    display: flex;
    align-items: center;
    gap: 12px;
    border-radius: 12px;
    padding: 8px;
    cursor: pointer;
    color: $grey-1;
    text-decoration: none;
    .download-file:not(._sm) & {
      &:hover {
        background: $light-background;
      }
    }

    &._pdf {
      padding: 8px 10px;
      .download-file._sm & {
        display: none;
      }
    }
    &._download {
      gap: 7px;
      span {
        display: none;
        color: $grey-2;
      };
      color: #DFDFEB;
      &:hover {
        color: $accent-color
      }
      .download-file._sm & span{
        display: block;
        
      }
    }
  }

  .download-file__divider {
    height: 100%;
    width: 1px;
    background: $grey-6;
    height: 26px;
    .download-file._sm & {
      display: none;
    }
  }
</style>

