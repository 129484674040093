<template lang="pug">
  .base-status(v-if="status !== null" :title="hoverDisable ?  null : statusDescription")
    icon(:name="icon")
    span(v-if="!onlyIcon") {{statusDescription}}
</template>

<script>
//  Статус документа вычисляется на фронте / файла и заказа на беке
// TODO documentAllStatuses к единому и импорт
import {documentAllStatuses as documentAllStatusesObj} from '@/utils/docStatus.js';
// const documentAllStatusesArr = Object.values(documentAllStatusesObj)
const documentAllStatusesArr = [
    {
        "value": 0,
        "description": "Распознается"
    },
    {
        "value": 1,
        "description": "Успешно распознан"
    },
    {
        "value": 2,
        "description": "Ошибка"
    },
    {
        "value": 3,
        "description": "Частично распознан"
    }
]

const statusIcon = {
  order: {
    1: 'doc-error-icon',
    2: 'doc-error-icon',
    3: 'doc-process-icon',
    4: 'doc-process-icon',
    5: 'doc-success-icon',
  },
  file: {
    0: 'doc-process-icon',
    1: 'doc-process-icon',
    2: 'doc-error-icon',
    3: 'doc-process-icon',
    4: 'doc-error-icon',
    5: 'doc-progress-icon',
    6: 'doc-progress-icon',
    7: 'doc-success-icon',
    8: 'doc-progress-icon',
    9: 'doc-error-icon',
  },
  document: {
    // process: documentAllStatusesObj.process.icon,
    // progress: documentAllStatusesObj.progress.icon,
    // success: documentAllStatusesObj.success.icon,
    // error: documentAllStatusesObj.error.icon
    0: documentAllStatusesObj.process.icon,
    1: documentAllStatusesObj.success.icon,
    2: documentAllStatusesObj.error.icon,
    3: documentAllStatusesObj.progress.icon,
  },
}

export default {
  data(){
    return {
      statusIcon
    }
  },
  props: {
    hoverDisable: Boolean,
    type: {
      type: String,
      default: 'order',
      validator: function (value) {
        return ['order', 'file', 'document'].includes(value)
      }
    },
    status: {
      type: [Number, String ]
    },
    onlyIcon: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    allStatuses() {
      if (this.isDocument) return documentAllStatusesArr
      else return this.$store.state.staticVariables.staticVariables?.[`${this.type}_statuses`];
    },
    statusDescription() {
      const status = this.allStatuses?.find((el) => el.value === this.status);
      return status?.description || String(this.status);
    },
    docStatus(){
      return 'error'
    },
    isDocument(){
      return this.type === 'document'
    },
    icon() {
      const {statusIcon, type, status} = this
      return statusIcon[type][status] || 'doc-question-icon'
    }

  },
}
</script>

<style lang="scss">
  .base-status {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 12px;
    @include media-down($breakpoint-md){
      font-size: 14px;
    }
    color: $grey-2
  }
</style>