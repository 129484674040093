<template lang="pug">
.quantity-price
  .quantity-price__quantity {{biomarkerQuantity}}
  icon.quantity-price__circle(width="3" name="circle" color="#7C74E9")
  .quantity-price__price {{formatMoney(price)}} ₽
</template>


<script>
import { formatMoney } from '@/utils/utils.ts'
import { pluralizeText} from '@/utils/utils';

export default {
  props: ['quantity', 'price'],
  methods: {
    formatMoney
  },
  computed: {
    biomarkerQuantity() {
      const ru = ['биомаркер', 'биомаркера', 'биомаркеров'];
      return `${this.quantity} ${pluralizeText(this.quantity, ru)}`;
    }
  }
}
</script>

<style lang="scss">
.quantity-price {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: $grey-3;
  flex-wrap: nowrap;
}

.quantity-price__quantity, .quantity-price__price, .quantity-price__circle {
  flex-shrink: 0;
}
</style>