<template lang="pug">
BaseBtn(@click="isModalActive = true" size="md").freeTariffButton
  span Тариф Free
  icon(name="question2").freeTariffButton-icon
  MessageModal(size="sm" v-model="isModalActive")
    template(#body)
      .h3.weight-medium Ограничения по тарифу
      .h3.weight-medium.text-primary LabStory Free
      div(v-if="modalType === 'dashboard'")
        span На бесплатном тарифе вы можете загружать до 5 документов и до 5 анализов для расшифровки и оцифровки. На загрузку результотов вручную ограничений нет 👌
      div(v-if="modalType === 'analyzes'")
        span На бесплатном тарифе вы можете просматривать динамику показателя только за год с момента получения или загрузки последнего результата. 
      span Вы можете повысить тариф за 290 ₽ в месяц и расширить возможности приложения — там много полезного и нет ограничений на загрузку 🙂
    template(#buttons)
      BaseBtn(@click="$router.push('/tariffs')" size="sm") Смотреть тарифы
</template>

<script>
import MessageModal from '@/components/modals_new/templates/MessageModal.vue';
export default {
  components: {MessageModal},
  data(){
    return {
      isModalActive: null
    }
  },
  props: ['modalType'] //'dashboard', 'analyzes'
}


</script>

<style lang="scss">
.freeTariffButton {
  background: #FFFFFF !important;
  color: $grey-2 !important;
  border: 1px solid transparent !important;
  &:hover {
    background: transparent !important;
    border: 1px solid $light-stroke !important;
  }
}

.freeTariffButton-icon {
  width: 18px;
  height: 18px;
  circle {
    stroke: $accent-color
  }
  path {
    fill: $accent-color
  }
}
</style>

