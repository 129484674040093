import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ILayout } from '@/interfaces/layout.interface';
import { IRouter } from '@/interfaces/router.interface';
var ROUTE_NAME = IRouter.ROUTE_NAME;
;
import DocItem from '@/components/items/DocItem.vue';
let AddedDocuments = class AddedDocuments extends Vue {
    constructor() {
        super(...arguments);
        this.breakPoint = ILayout.Breakpoint;
    }
    beforeShow() {
        if (this.isMobile) {
            this.$refs.dock.hide();
            this.$router.push({ name: ROUTE_NAME.STORAGE_PAGE });
        }
    }
    // to store
    get isMobile() {
        return window.screen.width <= 575;
    }
    get documentTypes() {
        return this.$store.state.staticVariables.staticVariables?.document_types || [];
    }
    get orderStatuse() {
        return this.$store.state.staticVariables.staticVariables?.order_statuses || [];
    }
    get newDocsCounter() {
        let localStorageViewedDocs = window.localStorage?.['viewedDocs'];
        if (!localStorageViewedDocs)
            return this.dashboardDocuments.length;
        localStorageViewedDocs = JSON.parse(localStorageViewedDocs);
        const viewedDocs = this.dashboardDocuments.filter(item => {
            return !localStorageViewedDocs.includes(item.id) && [1, 2, 3].includes(item.status);
        });
        return viewedDocs.length;
    }
    get newDocs() {
        return this.dashboardDocuments.slice(0, 15);
    }
};
__decorate([
    Prop()
], AddedDocuments.prototype, "dashboardDocuments", void 0);
AddedDocuments = __decorate([
    Component({ components: { DocItem } })
], AddedDocuments);
export default AddedDocuments;
