export var IDashBoard;
(function (IDashBoard) {
    let TabsName;
    (function (TabsName) {
        TabsName["BENCHMARKS"] = "benchmarks";
        TabsName["LATEST_RESULTS"] = "latest-results";
    })(TabsName = IDashBoard.TabsName || (IDashBoard.TabsName = {}));
    let BioMarkersStatus;
    (function (BioMarkersStatus) {
        BioMarkersStatus["PROCESS"] = "\u0418\u0441\u0441\u043B\u0435\u0434\u0443\u0435\u0442\u0441\u044F";
        BioMarkersStatus["DONE"] = "\u0413\u043E\u0442\u043E\u0432\u043E";
    })(BioMarkersStatus = IDashBoard.BioMarkersStatus || (IDashBoard.BioMarkersStatus = {}));
})(IDashBoard || (IDashBoard = {}));
